<template>
  <div id="container">
    <!-- Top ones left to right -->
    <div>
      <div class="fixed left-48 top-24 rotate">
        <img
          class="mx-auto"
          src="https://res.cloudinary.com/zillaafrica/image/upload/v1642419886/customer/Group_6_b6iq1p.svg"
          alt="success"
        />
      </div>
      <div class="fixed right-32 top-24 rotate">
        <img
          class="h-10 w-10 mx-auto"
          src="https://res.cloudinary.com/zillaafrica/image/upload/v1642419886/customer/Group_6_b6iq1p.svg"
          alt="success"
        />
      </div>

      <!-- Bottom icons left to right -->
      <div>
        <div class="fixed left-24 bottom-0 rotate">
          <img
            class="h-48 w-48 mx-auto"
            src="https://res.cloudinary.com/zillaafrica/image/upload/v1642419886/customer/Group_6_b6iq1p.svg"
            alt="success"
          />
        </div>
        <div class="fixed right-56 bottom-56 rotate">
          <img
            class="h-20 w-20 mx-auto"
            src="https://res.cloudinary.com/zillaafrica/image/upload/v1642419886/customer/Group_6_b6iq1p.svg"
            alt="success"
          />
        </div>
      </div>
    </div>
    <div class="h-full flex justify-center mx-10">
      <div>
        <img
          class="h-96 w-96 mx-auto"
          src="https://res.cloudinary.com/zillaafrica/image/upload/v1642418035/customer/Frame_12969_cfsbib.svg"
          alt="success"
        />
        <p class="text-center text-white my-8">
          We’re sorry, this is most likely not where you <br />
          intend to be, something must be missing
        </p>
        <div class="flex justify-center items-center">
          <Button
            class="mt-4"
            background="bg-white"
            color="text-transparent"
            text="RETURN HOME"
            width="w-48"
            fontSize="text-sm"
            @click="$router.push('/')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Button } from "@/UI/Button";
  export default {
    components: {
      Button,
    },
  };
</script>

<style scoped>
  #container {
    background: linear-gradient(
      119.39deg,
      #c6003b -1.51%,
      #6a30c3 22.47%,
      #63dfdf 103.34%
    );
    width: 100%;
    height: 100vh;
  }
  .rotate {
    animation: rotation 15s infinite alternate;
  }
  @keyframes rotation {
    from {
      transform: rotate(0deg) translateX(-50%);
    }
    to {
      transform: rotate(359deg);
    }
  }
</style>
