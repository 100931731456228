<template>
  <PageNotFound />
</template>
<script>
import PageNotFound from "@/components/404";
export default {
  components: {
    PageNotFound,
  },
};
</script>
