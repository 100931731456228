var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "container" } }, [
    _vm._m(0),
    _c("div", { staticClass: "h-full flex justify-center mx-10" }, [
      _c("div", [
        _c("img", {
          staticClass: "h-96 w-96 mx-auto",
          attrs: {
            src: "https://res.cloudinary.com/zillaafrica/image/upload/v1642418035/customer/Frame_12969_cfsbib.svg",
            alt: "success",
          },
        }),
        _vm._m(1),
        _c(
          "div",
          { staticClass: "flex justify-center items-center" },
          [
            _c("Button", {
              staticClass: "mt-4",
              attrs: {
                background: "bg-white",
                color: "text-transparent",
                text: "RETURN HOME",
                width: "w-48",
                fontSize: "text-sm",
              },
              on: {
                click: function ($event) {
                  return _vm.$router.push("/")
                },
              },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "fixed left-48 top-24 rotate" }, [
        _c("img", {
          staticClass: "mx-auto",
          attrs: {
            src: "https://res.cloudinary.com/zillaafrica/image/upload/v1642419886/customer/Group_6_b6iq1p.svg",
            alt: "success",
          },
        }),
      ]),
      _c("div", { staticClass: "fixed right-32 top-24 rotate" }, [
        _c("img", {
          staticClass: "h-10 w-10 mx-auto",
          attrs: {
            src: "https://res.cloudinary.com/zillaafrica/image/upload/v1642419886/customer/Group_6_b6iq1p.svg",
            alt: "success",
          },
        }),
      ]),
      _c("div", [
        _c("div", { staticClass: "fixed left-24 bottom-0 rotate" }, [
          _c("img", {
            staticClass: "h-48 w-48 mx-auto",
            attrs: {
              src: "https://res.cloudinary.com/zillaafrica/image/upload/v1642419886/customer/Group_6_b6iq1p.svg",
              alt: "success",
            },
          }),
        ]),
        _c("div", { staticClass: "fixed right-56 bottom-56 rotate" }, [
          _c("img", {
            staticClass: "h-20 w-20 mx-auto",
            attrs: {
              src: "https://res.cloudinary.com/zillaafrica/image/upload/v1642419886/customer/Group_6_b6iq1p.svg",
              alt: "success",
            },
          }),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "text-center text-white my-8" }, [
      _vm._v(" We’re sorry, this is most likely not where you "),
      _c("br"),
      _vm._v(" intend to be, something must be missing "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }